<template>
  <div>
    <Header />
    <div class="bg-mid-grey mt-5">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-5 pb-5">
            <h1 class="page-header">Drug / Timeframe</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-light-grey">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-5">
            <h5>
              <strong>Choice of drugs</strong> - select the drugs you want to compare:
            </h5>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-4">
            <button
              v-if="drugs.includes('byoviz')"
              @click="unSelectDrug('byoviz')"
              class="expanded button blue-active tall">Byooviz</button>
            <button
              v-else
              @click="selectDrug('byoviz')"
              class="expanded button blue-ghost tall">Byooviz</button>
          </div>
          <div class="cell small-12 medium-4">
            <button
              v-if="drugs.includes('lucentis')"
              @click="unSelectDrug('lucentis')"
              class="expanded button blue-active tall">Lucentis</button>
            <button
              v-else
              @click="selectDrug('lucentis')"
              class="expanded button blue-ghost tall">Lucentis</button>
          </div>
          <div class="cell small-12 medium-4">
            <button
              v-if="drugs.includes('biosimilars 2')"
              @click="unSelectDrug('biosimilars 2')"
              class="expanded button blue-active tall">biosimilar 2</button>
            <button
              v-else
              @click="selectDrug('biosimilars 2')"
              class="expanded button blue-ghost tall">biosimilar 2</button>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <hr class="wide orange-line" />
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-1">
            <h5>
              <strong>Indications</strong><sup>5,6</sup>
              - select the indications you want to compare:
            </h5>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-2 pt-5">
            <button
              v-if="indications.includes('wamd')"
              @click="unselectIndication('wamd')"
              class="button keep-case expanded tall blue-active">wAMD</button>
              <button
                v-else
                @click="selectIndication('wamd')"
                class="button keep-case expanded tall blue-ghost">wAMD</button>
          </div>
          <div class="cell small-12 medium-2 pt-5">
            <button
              v-if="indications.includes('dme')"
              @click="unselectIndication('dme')"
              class="button expanded tall blue-active">DME</button>
            <button
              v-else
              @click="selectIndication('dme')"
              class="button expanded tall blue-ghost">DME</button>
          </div>
          <div class="cell small-12 medium-2 pt-5">
            <button
              v-if="indications.includes('pdr')"
              @click="unselectIndication('pdr')"
              class="button expanded tall blue-active">PDR</button>
            <button
              v-else
              @click="selectIndication('pdr')"
              class="button expanded tall blue-ghost">PDR</button>
          </div>
          <div class="cell small-12 medium-2 pt-5">
            <button
              v-if="indications.includes('rvo')"
              @click="unselectIndication('rvo')"
              class="button expanded tall blue-active">RVO</button>
            <button
              v-else
              @click="selectIndication('rvo')"
              class="button expanded tall blue-ghost">RVO</button>
          </div>
          <div class="cell small-12 medium-2 pt-5">
            <button
              v-if="indications.includes('cnv')"
              @click="unselectIndication('cnv')"
              class="button expanded tall blue-active">CNV</button>
            <button
              v-else
              @click="selectIndication('cnv')"
              class="button expanded tall blue-ghost">CNV</button>
          </div>
          <div class="cell small-12 medium-2 pt-5">
            <button
              v-if="indications.length === 5"
              @click="unselectIndication('all')"
              class="button expanded tall blue-active">All</button>
            <button
              v-else
              @click="selectIndication('all')"
              class="button expanded tall blue-ghost">ALL</button>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <hr class="wide orange-line" />
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-4">
            <h5>Select timeframe you wish to model:</h5>
          </div>
          <div class="cell small-12 medium-6 medium-offset-2">
            <h5>Patient type:</h5>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-2">
            <button
              v-if="timeframe === 1"
              @click="selectTimeframe(3)"
              class="button tall expanded blue-active">1 Year</button>
            <button
              v-else
              @click="selectTimeframe(1)"
              class="button tall expanded blue-ghost">1 Year</button>
          </div>
          <div class="cell small-12 medium-2">
            <button
              v-if="timeframe === 3"
              @click="selectTimeframe(1)"
              class="button tall expanded blue-active">3 Years</button>
            <button
              v-else
              @click="selectTimeframe(3)"
              class="button tall expanded blue-ghost">3 Years</button>
          </div>
          <div class="cell small-12 medium-2 medium-offset-2">
            <button
              v-if="patientTypes.includes('new')"
              @click="unselectPatientType('new')"
              class="button tall expanded blue-active">New</button>
            <button
              v-else
              @click="selectPatientType('new')"
              class="button tall expanded blue-ghost">New</button>
          </div>
          <div class="cell small-12 medium-2">
            <button
              v-if="patientTypes.includes('switch')"
              @click="unselectPatientType('switch')"
              class="button tall expanded blue-active">Switch</button>
            <button
              v-else
              @click="selectPatientType('switch')"
              class="button tall expanded blue-ghost">Switch</button>
          </div>
          <div class="cell small-12 medium-2">
            <button
              v-if="patientTypes.length === 2"
              @click="unselectPatientType('both')"
              class="button tall expanded blue-active">Both</button>
            <button
              v-else
              @click="selectPatientType('both')"
              class="button tall expanded blue-ghost">Both</button>
          </div>
        </div>
        <div class="grid-x grid-padding-x pt-5 pb-5">
          <div class="cell small-12">
            <p class="text-small float-left pt-4">
              <strong>CNV</strong>, choroidal neovascularisation;
              <strong>DME</strong>, diabetic macular oedema;
              <strong>PDR</strong>, proliferative diabetic retinopathy;
              <strong>RVO</strong>, retinal vein occlusion;
              <strong>wAMD</strong>, wet age-related macular degeneration (AMD)
            </p>
            <router-link class="float-right" to="/costs">
              <button class="button">Next</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
  name: 'DrugsTimeline',
  components: {
    Header,
  },
  computed: {
    hash() {
      return this.$store.state.hash;
    },
    drugs() {
      return this.$store.state.drugs;
    },
    indications() {
      return this.$store.state.indications;
    },
    timeframe() {
      return this.$store.state.timeframe;
    },
    patientTypes() {
      return this.$store.state.patientTypes;
    },
  },
  methods: {
    selectDrug(drug) {
      this.$store.commit('setDrugs', drug);
    },
    unSelectDrug(drug) {
      this.$store.commit('unsetDrugs', drug);
    },
    selectIndication(indication) {
      this.$store.commit('setIndication', indication);
    },
    unselectIndication(indication) {
      this.$store.commit('unsetIndication', indication);
    },
    selectTimeframe(years) {
      this.$store.commit('setTimeframe', years);
      if (years === 1) {
        this.$store.commit('updatelucentisYearTwoShare', 0);
        this.$store.commit('updatelucentisYearThreeShare', 0);
        this.$store.commit('updatebyovizYearTwoShare', 0);
        this.$store.commit('updatebyovizYearThreeShare', 0);
        this.$store.commit('updatebiosimilarYearTwoShare', 0);
        this.$store.commit('updatebiosimilarYearThreeShare', 0);
        this.$store.commit('updatelucentisYearTwoFutureShare', 0);
        this.$store.commit('updatelucentisYearThreeFutureShare', 0);
        this.$store.commit('updatebyovizYearTwoFutureShare', 0);
        this.$store.commit('updatebyovizYearThreeFutureShare', 0);
        this.$store.commit('updatebiosimilarYearTwoFutureShare', 0);
        this.$store.commit('updatebiosimilarYearThreeFutureShare', 0);
        this.$store.commit('updatebyovizYearTwoShareSwitch', 0);
        this.$store.commit('updatebyovizYearTwoFutureShareSwitch', 0);
        this.$store.commit('updatebyovizYearThreeShareSwitch', 0);
        this.$store.commit('updatebyovizYearThreeFutureShareSwitch', 0);
        this.$store.commit('updatelucentisYearTwoShareSwitch', 0);
        this.$store.commit('updatelucentisYearTwoFutureShareSwitch', 0);
        this.$store.commit('updatelucentisYearThreeShareSwitch', 0);
        this.$store.commit('updatelucentisYearThreeFutureShareSwitch', 0);
        this.$store.commit('updatebiosimilarYearTwoShareSwitch', 0);
        this.$store.commit('updatebiosimilarYearTwoFutureShareSwitch', 0);
        this.$store.commit('updatebiosimilarYearThreeShareSwitch', 0);
        this.$store.commit('updatebiosimilarYearThreeFutureShareSwitch', 0);
      }
    },
    selectPatientType(type) {
      this.$store.commit('setPatientType', type);
    },
    unselectPatientType(type) {
      this.$store.commit('unsetPatientType', type);
    },
    sendPage() {
      this.$store.commit('setPage', 'drugTimeline');
    },
    checkHashExists() {
      if (this.hash.length === 0) {
        this.$router.push('/inactive-link');
      }
    },
  },
  mounted() {
    this.checkHashExists();
    this.sendPage();
  },
};
</script>
